<template>
    <!-- eslint-disable -->
    <div class="columns is-centered is-marginless" style="height: 100%">
        <div
        class="column is-8"
        style="height: 100%"
        >
        <div class="column is-narrow">
            <h1 class="is-size-4 has-text-weight-medium">
            Settings
            </h1>
        </div>

        <b-tabs v-model="tab" :animated="false">
            <b-tab-item v-for="(tab, t) in tabs" :key="t" :label="tab.label">
                <component :is="tab.component" v-bind="tabs[t].props">
                    <template #heading>
                        <h2 class="is-size-3 has-text-weight-medium">{{ tab.label }}</h2>
                    </template>
                </component>
            </b-tab-item>
        </b-tabs>
        </div>
    </div>
</template>

<script>
/*eslint-disable */ 
import { get } from '@/api/setting';

import Organization from './Organization';
import Appointment from './Appointment';
import Paystack from './Paystack';
import AfricaTalking from './AfricaTalking';
import Mailgun from './Mailgun';
import Intercom from './Intercom';

export default {
    name: "Settings",
    metaInfo() {
        return {
            title: `Setting${this.tab !== null ?` - ${this.tabs[this.tab].label}` : ''}`,
        };
    },
    components: {
        Organization, Appointment, Paystack, 
        AfricaTalking, Mailgun, Intercom,
    },
    data() {
        return {
            tab: null,
            config: {},
        };
    },

    computed: {
        tabs(){
            return [
                {
                    label: 'Organization',
                    component: 'organization',
                    props: {
                        config: this.config.organization,
                        settingsKey: 'organization',
                        editable: false,
                    },
                    description: 'Basic information about your organization'
                },
                {
                    label: 'Appointment',
                    component: 'appointment',
                    props: {
                        config: this.config.appointment,
                        settingsKey: 'appointment',
                    },
                    description: 'Configure appointment.'
                },
                {
                    label: 'Paystack',
                    component: 'paystack',
                    props: {
                        config: this.config.paystack,
                        settingsKey: 'paystack',
                    },
                    description: 'Paystack will be used to process payment, configure your public and secret keys.'
                },
                {
                    label: 'AfricaTalking',
                    component: 'africa-talking',
                    props: {
                        config: this.config.africaTalking,
                        settingsKey: 'africaTalking',
                    },
                    description: 'AftricaTalking is a service to power your appointment conference calls and SMS.'
                },
                {
                    label: 'Mailgun',
                    component: 'mailgun',
                    props: {
                        config: this.config.mailgun,
                        settingsKey: 'mailgun',
                    },
                    description: 'Mailgun service power your emails.'
                },
                {
                    label: 'Intercom',
                    component: 'intercom',
                    props: {
                        config: this.config.intercom,
                        settingsKey: 'intercom',
                    },
                    description: 'Intercom powers your customer support'
                }
            ]
        }
    },

    async mounted(){
        this.tab = this.$route.params.tab ? this.tabs.findIndex(t => t.component == this.$route.params.tab) : 0;

        const loadingComponent = this.$buefy.loading.open();

        const { data } = await get();

        this.config = data;

        loadingComponent.close();
    },

    watch: {
        tab: {
            immediate: false,
            handler(tab){
                this.$router.push({name: this.$route.name, params: {tab: this.tabs[tab].component}})
            }
        }
    }

};
</script>

<style>
    fieldset {
        border: 2px solid #f7f7f7;
        margin: 10px 0;
        padding: 10px;
    }
    fieldset legend {
        font-size: 1.5rem;
        font-weight: bold;
    }
</style>
